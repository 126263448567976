import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import css from "@styled-system/css";
import Link from "../components/Link";

const Container = styled.div(
  css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "800px",
    minHeight: "100vh",
    mx: "auto",
    px: 4,
  }),
);

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not Found" />
    <Container>
      <div>
        <h1>Looks like you got lost...</h1>
        <Link to="/">Click here to get help</Link>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
