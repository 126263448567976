import css from "@styled-system/css";
import { Link as GLink } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { colors } from "../styles";

const StyledLink = styled(GLink)<{ empty: number }>(props =>
  css({
    color: props.empty ? "inherit" : "primary",
    textDecoration: "underline",
    transition: "all 250ms ease-in-out",

    "&:hover": {
      color: props.empty ? "inherit" : "hover",
      textShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
    },
  }),
);

export interface Props {
  to: string;
  href?: string;
  empty?: boolean;
  target?: string;
  className?: string;
  newTab?: boolean;
}

const isExternalLink = (href: string): boolean =>
  href.startsWith("http://") ||
  href.startsWith("https://") ||
  href.startsWith("mailto:");

const Link: React.FC<Props> = props => {
  const href = props.href || props.to;
  if (isExternalLink(href) || props.newTab) {
    return (
      <StyledLink
        as="a"
        href={href}
        target="_blank"
        {...{ ...props, empty: props.empty ? 1 : 0 }}
      >
        {props.children}
      </StyledLink>
    );
  }

  return (
    <StyledLink
      to={href}
      activeClassName="active"
      {...{ ...props, empty: props.empty ? 1 : 0 }}
    >
      {props.children}
    </StyledLink>
  );
};

export default Link;

export const EmptyLink: React.FC<Props> = props => (
  <Link empty={true} {...props} />
);
